import request from '@/utils/request'

// 查询消息应用配置列表
export function listMessageApp (query) {
  return request({
    url: '/message/messageApp/list',
    method: 'get',
    params: query
  })
}

// 查询消息应用配置详细
export function getMessageApp (id) {
  return request({
    url: '/message/messageApp/' + id,
    method: 'get'
  })
}

// 新增消息应用配置
export function addMessageApp (data) {
  return request({
    url: '/message/messageApp',
    method: 'post',
    data: data
  })
}

// 修改消息应用配置
export function updateMessageApp (data) {
  return request({
    url: '/message/messageApp',
    method: 'put',
    data: data
  })
}

// 删除消息应用配置
export function delMessageApp (id) {
  return request({
    url: '/message/messageApp/' + id,
    method: 'delete'
  })
}

// 导出消息应用配置
export function exportMessageApp (query) {
  return request({
    url: '/message/messageApp/export',
    method: 'get',
    params: query
  })
}

// 通过渠道类型 获取应用配置
export function queryAppsByChannelType (channelType) {
  return request({
    url: '/message/messageApp/queryAppsByChannelType/' + channelType,
    method: 'get'
  })
}

// 通过渠道类型 获取应用配置
export function getAppConfigByChannelType (channelType) {
  return request({
    url: '/message/messageApp/getAppConfigByChannelType/' + channelType,
    method: 'get'
  })
}

// 获取应用类型
export function getAppCodes (query) {
  return request({
    url: '/message/messageApp/getAppCodes',
    method: 'get'
  })
}
