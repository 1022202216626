<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="用户账号" prop="userName">
                <a-input v-model="queryParam.userName" placeholder="请输入用户账号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="消息应用ID" prop="appId">
                <a-select placeholder="请选择消息应用" v-model="queryParam.appId" allow-clear>
                  <a-select-option v-for="(d, index) in appArray" :key="index" :value="parseInt(d.id)">{{
                    d.appName
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="用户昵称" prop="nickName">
                  <a-input v-model="queryParam.nickName" placeholder="请输入用户昵称" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="用户通信地址" prop="userChannelAddress">
                  <a-input v-model="queryParam.userChannelAddress" placeholder="请输入用户通信地址" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, userIds)" v-hasPermi="['tenant:message:userMessageBook:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['tenant:message:userMessageBook:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:message:userMessageBook:export']">
          <a-icon type="download" />导出
        </a-button>
        <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{float: 'right'}"
          icon="reload"
          @click="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :msgChannelTypeOptions="msgChannelTypeOptions"
        :appArray="appArray"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false">
        <span slot="userAppBookList" slot-scope="text, record">
              {{ showUserChannelAddress(record) }}
            </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:message:userMessageBook:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tenant:message:userMessageBook:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:message:userMessageBook:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:message:userMessageBook:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listUserMessageBook, delUserMessageBook, exportUserMessageBook } from '@/api/message/userMessageBook'
import { listMessageApp } from '@/api/message/messageApp'
import CreateForm from './modules/CreateForm'

export default {
  name: 'UserMessageBook',
  components: {
    CreateForm
  },
  data () {
    return {
      list: [],
      msgChannelTypeOptions: [],
      appArray: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      userIds: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userName: undefined,
        nickName: undefined,
        appId: undefined,
        userChannelAddress: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '用户ID',
          dataIndex: 'userId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户账号',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户昵称',
          dataIndex: 'nickName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户通信地址',
          dataIndex: 'userAppBookList',
          scopedSlots: { customRender: 'userAppBookList' },
          ellipsis: true,
          align: 'left'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
    this.getDicts('msg_channel_type').then((response) => {
      this.msgChannelTypeOptions = response.data
    })
    const queryParam = {
        pageNum: 1,
        pageSize: 100
      }
    listMessageApp(queryParam).then((response) => {
      this.appArray = response.rows
    })
  },
  computed: {
  },
  watch: {
  },
  methods: {
    showUserChannelAddress (record) {
      if (record && record.userAppBookList) {
        return record.userAppBookList.map(item => {
          const app = this.appArray.find(it => it.id === item.appId)
          if (app) {
            return app.appName + '：' + item.userChannelAddress
          }
        }).join('\n')
      }
      return undefined
    },
    /** 查询用户通讯录列表 */
    getList () {
      this.loading = true
      listUserMessageBook(this.queryParam).then(response => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userName: undefined,
        nickName: undefined,
        appId: undefined,
        channelType: undefined,
        userChannelAddress: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.userIds = this.selectedRows.map(item => item.userId)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const userIds = row.userId || this.userIds
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + userIds + '的数据',
        onOk () {
          return delUserMessageBook(userIds)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          return exportUserMessageBook(that.queryParam)
            .then(response => {
              that.download(response.msg)
              that.$message.success(
                '导出成功',
                3
              )
          })
        },
        onCancel () {}
      })
    }
  }
}
</script>
